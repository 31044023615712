import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from '../service/useScript';

const EmergencyServices = (props) => {
useScript("/assets/js/emergencyServices.js");
const { data } = props
let pageInfo = {}
let components = []
const [topSectionLinks, setTopSectionLinks] = useState([])
const [bottomSectionLinks, setBottomSectionLinks] = useState([])
let metaTags = data?.allNodeTemplatePages?.edges?.[0]?.node?.metatags || []
useEffect(() => {
if (data && data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
pageInfo = data.allNodeTemplatePages.edges[0]
components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
if (components && components.length) {
const top = components.filter(el => el.contact !== null)
const bottom = components.filter(el => el.link !== null)
setTopSectionLinks(top)
setBottomSectionLinks(bottom)
}
}
}, [])

const renderImage = (data) => {
let src = ''
let alt = ''
if (data && data?.relationships?.icon?.uri?.url) {
src = data.relationships.icon.uri?.url
}
if (data && data.icon && data.icon.alt) {
alt = data.icon.alt
}
if (src) {
return <img src={src} alt={alt} />
}
return null
}
const renderTitle = (title) => {
let arr = []
if (title.includes(" ")) {
arr = title.split(" ")
return <span>{arr[0]}<br />{arr[1]}</span>
} else {
arr = title.split("/")
return <span>{arr[0]} /<br />{arr[1]}</span>
}
}
const renderLink = (data) => {
let url = data && data.link && data.link.uri ? data.link.uri : ''
const isAbsolute = url.includes('http')
if (isAbsolute === true) {
return (
<a href={url} target="_blank">
{
renderImage(data)
}
{data.title ? renderTitle(data.title) : ''}
</a>
)
} else {
if (data && data.link && data.link.uri && data.link.uri.includes('internal:')) {
const arr = data.link.uri.split('internal:')
if (arr && arr.length) {
url = arr[1]
return (
<Link to={url}>
{
renderImage(data)
}
{data.title ? renderTitle(data.title) : ''}
{/* <span>Emergency <br />Contact</span> */}
</Link>
)
}
}
}
}
return (
<Layout>
    <Meta
    files={
    {
    js: [],
    css: ["/assets/css/emergency-services.css"]
    }
    }
    tags={metaTags}
    />
    <main className="innerpage">
        <section className="emergency_services pt-first-section section-bg section-py">
            <div className="container">
                <div className="row">
                    {
                    (topSectionLinks && topSectionLinks.length) &&
                    topSectionLinks.map((el, index) => {
                    return (
                    <div key={index} className="col-md-4">
                        <div className="service_box">
                            <figure>
                                {
                                renderImage(el)
                                }
                            </figure>
                            <div className="service_content">
                                <h3>{el.title ? el.title : ''}</h3>
                                <p className="emergency_num">{el.contact ? el.contact : ''}</p>
                            </div>
                        </div>
                    </div>
                    )
                    })
                    }
                </div>
            </div>
        </section>
        <section className="emergency_btm_box">
            <div className="container">
                <ul>
                    {
                    (bottomSectionLinks && bottomSectionLinks.length) &&
                    bottomSectionLinks.map((el, index) => {
                    return (
                    <li key={index}>
                        {
                        renderLink(el)
                        }
                    </li>
                    )
                    })
                    }
                </ul>
            </div>
        </section>
    </main>
</Layout>
)
}
export const query = graphql`
query emergencyPage {
allNodeTemplatePages(filter: { path: { alias: { regex: "/emergency$/" } } }) {
edges {
node {
id
title
path {
alias
}
metatag {
    attributes {
        content
        href
        name
        property
        rel
    }
}
relationships {
components: field_component_type {
__typename
...ParagraphEmergencyInfo
}
}
}
}
}
}
fragment ParagraphEmergencyInfo on paragraph__emergency_info {
id
title: field_title
contact: field_contact
link: field_link {
uri
}
icon: field_icon {
alt
}
relationships {
icon: field_icon {
id
uri {
    value
    url
}
}
}
}  
`
export default EmergencyServices